import React from "react";
import circle from "../assets/ellipse2.png";
import pic1 from "../assets/ondemand1.svg";
import pic2 from "../assets/ondemand2.svg";
import pic3 from "../assets/ondemand3.svg";

const ondemandList = [
  {
    image: pic1,
    heading: "Room Scheduling",
    text: "The room scheduling feature enables employees to schedule meetings and reserve conference rooms, ensuring that they have a designated space for collaboration or presentations. This feature also helps managers to optimize the use of available office space and ensure that conference rooms are not left vacant.",
  },
  {
    image: pic2,
    heading: "Desk Booking",
    text: "The desk booking feature allows employeesto reserve desks or workstations, ensuring that they have a designated space to work when they are on-site. This feature also helps managers to optimize the use of available office space, ensuring that there are enough workstations for employees when they are in the office.",
  },
  {
    image: pic3,
    heading: "Visitor Management",
    text: "The visitor management feature enables managers to streamline the visitor check-in process, ensuring that visitors are greeted and processed efficiently. This feature also enhances the visitor experience, ensuring that visitors have a positive impression of the company and its operations.",
  },
];

const Ondemand = () => {
  return (
    <section id="on-demand" className="bg-black relative overflow-hidden">
      <img className="absolute top-16 h-[420px] -right-8" src={circle}></img>
      <div className="relative p-default py-default">
        <h2 className="text-center">On-demand Solution</h2>
        <div className="flex flex-wrap mt-12 gap-8 sm:justify-center">
          {ondemandList &&
            ondemandList.map((ondemand, index) => (
              <article className="max-w-xs w-full" key={index}>
                <img
                  className="w-14"
                  src={ondemand.image}
                  alt={ondemand.heading}
                ></img>
                <h3 className="mt-4">{ondemand.heading}</h3>
                <p className="mt-4">{ondemand.text}</p>
              </article>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Ondemand;
