import React from "react";
import circle from "../assets/ellipse2.png";
import circle2 from "../assets/ellipse-lg.png";
import tick from "../assets/tick.svg";

const addonList = [
  "The software usage statistics reporting feature provides managers with data on how software is being used by employees, allowing them to make informed decisions on software licensing and utilization.",
  "The management and personal reporting feature enables managers to track employee performance, identify areas for improvement, and make data-driven decisions about workforce planning.",
  "The automated employee notifications feature enables managers to automate the process of notifying employees about upcoming deadlines, project updates, or any other relevant information, ensuring that employees are always up-to-date and informed.",
  "The automated invoices and payments feature streamlines the process of invoicing and payment, ensuring that payments are made on time and that billing is accurate.",
  "The accounting software synchronization feature allows for seamless integration between the office management software and accounting software, eliminating the need for manual data entry and reducing the risk of errors.",
];

const Addons = () => {
  return (
    <section id="addons" className="bg-black relative overflow-hidden">
      <img className="absolute top-0 -left-16 h-[100%]" src={circle}></img>
      <img
        className="absolute bottom-0 -right-16 w-[120vw] h-[50%]"
        src={circle2}
        alt=""
      ></img>
      <div className="relative p-default py-default">
        <h2 className="text-center">Add-on Features</h2>
        <div className="flex flex-col mt-12 md:mt-20 gap-y-8 md:gap-y-12 max-w-lg mx-auto">
          {addonList &&
            addonList.map((addon, index) => (
              <article className="flex items-start gap-x-4" key={index}>
                <img className="w-14" src={tick} alt=""></img>
                <p>{addon}</p>
              </article>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Addons;
