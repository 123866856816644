import React from "react";
import icon from "../assets/Icon-sm.svg";

const About = () => {
  return (
    <section id="about" className="bg-black">
      <div className="p-default py-default flex flex-col md:grid md:grid-cols-2 md:gap-4 lg:gap-8">
        <h4>Never before workplace management been as...</h4>
        <div className="flex flex-col mt-8 md:row-span-2 md:justify-center">
          <div className="flex items-center justify-start">
            <img className="mr-4 w-8" src={icon} alt=""></img>
            <h3>Effective</h3>
          </div>
          <div className="flex items-center mt-4 ml-[5vw]">
            <img className="mr-4 w-8" src={icon} alt=""></img>
            <h3>Simple</h3>
          </div>
          <div className="flex items-center mt-4 ml-[10vw]">
            <img className="mr-4 w-8" src={icon} alt=""></img>
            <h3>Sustainable</h3>
          </div>
        </div>
        <p className="mt-8">
          Transform your business with a user-friendly room scheduling software
          for a flawless work process. Manage your hybrid workspaces and
          environment in one customizable platform.
        </p>
      </div>
    </section>
  );
};

export default About;
