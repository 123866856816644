import React from "react";
import left_arrow from "../assets/left-arrow.svg";
import right_arrow from "../assets/right-arrow.svg";
import circle from "../assets/ellipse-lg.png";
import circle2 from "../assets/ellipse2.png";

const featuresList = [
  "The workplace booking system allows employees to reserve desks, conference rooms, or other resources, ensuring that they are available when needed. The workstation locker and device access management feature enables secure access to workstations and other devices, ensuring that only authorized personnel can access them.",
  "Time tracking helps managers track employee work hours and ensure they are being productive.",
  "The internal messenger enables employees to communicate with each other, share filesand collaborate on projects.",
  "Staff availability indicators help managers keep track of their team members' availability and schedule work accordingly.",
  "The business trip planner allows for efficient planning and management of  business trips, including travel arrangements and expenses.",
  "Emergency alerts help ensure that employees are informed of any urgent situations that may arise.",
  "The AI toolkit for improving productivity uses machine learning algorithms to analyze employee performance data and provide insights for improvement.",
  "The workload reporting feature provides managers with a clear overview of their team’s workload, helping them to allocate resources more effectively.",
  "Personnel and team effectiveness reports enable managers to track employee performance, identify areas for improvement, and make data-driven decisions about resource allocation and workforce planning.",
];

const Features = () => {
  return (
    <section id="features" className="bg-black relative overflow-hidden">
      <img
        className="absolute -top-4 -left-32 w-[120vw] h-[50%]"
        src={circle}
        alt=""
      ></img>
      <img
        className="absolute -bottom-16 -right-32 w-[120vw] h-[80%]"
        src={circle2}
        alt=""
      ></img>
      <div className="relative py-default">
        <h2 className="text-center p-default">Basic Features</h2>
        <div className="no-scroll pl-4 md:p-default flex items-stretch mt-12 md:mt-20 gap-x-4 overflow-x-scroll md:hidden">
          {featuresList &&
            featuresList.map((feature, index) => (
              <article
                key={index}
                className="flex items-center gap-x-4 px-4 py-8 gradient-border bg-transparent rounded-[20px] min-w-[300px] h-auto"
              >
                <p className="num-lg">{index + 1}</p>
                <p className="my-auto">{feature}</p>
              </article>
            ))}
        </div>
        <div className="flex p-default items-center justify-center gap-x-4 mt-8 md:hidden">
          <img
            className="w-16 cursor-pointer"
            src={left_arrow}
            alt="Previous"
          ></img>
          <img
            className="w-16 cursor-pointer"
            src={right_arrow}
            alt="Next"
          ></img>
        </div>
        <div className="no-scroll p-default md:p-default hidden md:flex flex-col mt-20 gap-12 max-w-[1024px] mx-auto">
          {featuresList &&
            featuresList.map((feature, index) => (
              <article
                key={index}
                className={
                  "flex items-center gap-x-4 px-4 py-8 gradient-border bg-transparent rounded-[20px] max-w-[640px] w-full " +
                  (index % 2 === 0 ? "mr-auto" : "ml-auto")
                }
              >
                <p className="num-lg">{index + 1}</p>
                <p className="my-auto">{feature}</p>
              </article>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
