import React from "react";
import pic from "../assets/lines-sm.svg";
import logo from "../assets/logo.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Footer = () => {
  return (
    <section id="footer" className="bg-black overflow-hidden relative">
      <img className="w-full" src={pic} alt=""></img>
      <div className="p-default pt-16 lg:flex lg:justify-between lg:gap-12">
        <div className="md:flex items-start justify-around lg:justify-between">
          <img className="w-36 mx-auto md:mx-0" src={logo} alt="zionys"></img>
          <div className="mt-12 md:mt-0 lg:ml-12">
            <p className="">ENESTECH SOFTWARE LTD</p>
            <p className="mt-4">Vasili Michailidi, 9 3026, Limasol, Cyprus</p>
          </div>
        </div>
        <div className="grid md:flex md:items-start md:justify-around grid-cols-2 gap-8 mt-12 lg:mt-0 lg:flex-1">
          <div className="flex flex-col gap-y-4">
            <h5>Content</h5>
            <AnchorLink href="#solutions">Solutions</AnchorLink>
            <AnchorLink href="#benefits">Benefits</AnchorLink>
            <AnchorLink href="#features">Features</AnchorLink>
            <AnchorLink href="#integrations">Integrations</AnchorLink>
            <AnchorLink href="#contacts">Contacts</AnchorLink>
          </div>
          <div className="flex flex-col gap-y-4">
            <h5>Social media</h5>
            <a href="#">Facebook</a>
            <a href="#">Instagram</a>
            <a href="#">Linkedin</a>
            <a href="#">Twitter</a>
          </div>
          <div className="flex flex-col gap-y-4">
            <h5>Company</h5>
            <a
              href="https://docs.google.com/document/d/1pqiX63HSoXKZ1_rOQJhc-MvrT3Bb2IRw/edit?usp=sharing&ouid=110479191688804255467&rtpof=true&sd=true"
              target="_blank"
              rel="nofollow"
            >
              Privacy Policy
            </a>
            <a
              href="https://docs.google.com/document/d/1IHnJYRuQuVJRz4tG__W8noBHr8lgiRSH/edit?usp=sharing&ouid=110479191688804255467&rtpof=true&sd=true"
              target="_blank"
              rel="nofollow"
            >
              Terms and Conditions
            </a>
          </div>
        </div>
      </div>
      <div className="mt-24 pb-8">
        <p className="text-white/50 md:text-center">
          ©2023 ENESTECH SOFTWARE LTD. ZIONYS.
        </p>
        <p className="text-white/50 md:text-center">All rights reserved</p>
      </div>
    </section>
  );
};

export default Footer;
