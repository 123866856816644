import React from "react";
import pic from "../assets/Integration.svg";
import ellipse from "../assets/ellipse3.png";
import circle from "../assets/ellipse-lg.png";
import circle2 from "../assets/ellipse2.png";

const Integrations = () => {
  return (
    <section id="integrations" className="bg-black overflow-hidden relative">
      <img className="absolute top-32 md:hidden" src={ellipse} alt=""></img>
      <img
        className="absolute top-16 left-20 w-[120vw] h-[70%]"
        src={circle}
        alt=""
      ></img>
      <img
        className="absolute bottom-8 right-0 w-[120vw] h-[80%]"
        src={circle2}
        alt=""
      ></img>
      <div className="p-default py-default relative flex flex-col">
        <h2 className="text-center">Integrations</h2>
        <p className="mt-4 text-center max-w-2xl mx-auto">
          Zionys effortlessly integrates with any other workspace management
          tools you may have. Its adaptability guarantees a seamless connection
          with your existing hardware and software environment.
        </p>
        <img
          className="mt-16 md:mt-24 max-w-2xl w-full mx-auto"
          src={pic}
          alt="Intergrations"
        ></img>
      </div>
    </section>
  );
};

export default Integrations;
