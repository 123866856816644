import React from "react";
import pic1 from "../assets/solution1.svg";
import pic2 from "../assets/solution2.svg";
import pic3 from "../assets/solution3.svg";
import pic4 from "../assets/solution4.svg";
import circle from "../assets/ellipse.png"

const solutionList = [
  {
    image: pic1,
    heading: "Powerful",
    text: "Zionys offers trong back-end features. Unlimited customizable workspace policies.",
  },
  {
    image: pic2,
    heading: "Intuitive",
    text: "Smart, easy-to-use software that is optimized for a fluid user experience.",
  },
  {
    image: pic3,
    heading: "Insightful",
    text: "Make data-driven decisions with real-time analytics to optimize your office.",
  },
  {
    image: pic4,
    heading: "Multi-location",
    text: "Manage your multi-location organization through one, easy-to-use system.",
  },
];

const Solutions = () => {
  return (
    <section id="solutions" className="bg-black relative overflow-hidden">
        <img className="absolute -top-8 h-96" src={circle}></img>
      <div className="relative p-default py-default">
        <h2 className="text-center">Zionys Solutions</h2>
        <div className="flex mt-12 gap-8 md:gap-12 sm:justify-center flex-wrap">
          {solutionList &&
            solutionList.map((solution, index) => (
              <article className="max-w-[260px]" key={index}>
                <img
                  className="w-14"
                  src={solution.image}
                  alt={solution.heading}
                ></img>
                <h3 className="mt-4">{solution.heading}</h3>
                <p className="mt-4">{solution.text}</p>
              </article>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Solutions;
