import React from "react";
import icon from "../assets/benefit.svg";
import left_arrow from "../assets/left-arrow.svg";
import right_arrow from "../assets/right-arrow.svg";
import circle from "../assets/ellipse-lg.png"

const benefitList = [
  "Flexible work benefits for your business",
  "Cost savings",
  "People satisfaction",
  "Business performance",
  "Reduced costs related to office space rental, equipment, recruitment charges, and travel expenses and optimize your workforce salary.",
  "Time optimization with less timein transport, employee happiness and better health due to lower stress levels, increased loyalty and team positive spirit.",
  "Increased productivity and working hours, accelerated talent acquisition with access to a global pool of talents and skills, creativity and motivationare boosted.",
];

const Benefits = () => {
  return (
    <section id="benefits" className="bg-black relative overflow-hidden">
      <img className="absolute -top-4 left-0 w-[120vw] h-[80%]" src={circle} alt=""></img>
      <div className="relative  py-default">
        <h2 className="text-center p-default">Key benefits</h2>
        <div className="no-scroll pl-4 md:p-default flex items-stretch mt-12 md:mt-20 gap-x-4 overflow-x-scroll md:overflow-x-hidden md:flex-wrap md:gap-8 md:justify-center">
          {benefitList &&
            benefitList.map((benefit, index) => (
              <article key={index} className="flex flex-col items-center p-12 gradient-border rounded-[20px] bg-th-dark min-w-[300px] md:max-w-xs md:w-full h-auto">
                <img className="w-14 mb-4" src={icon} alt=""></img>
                <p className="my-auto text-center">{benefit}</p>
              </article>
            ))}
        </div>
        <div className="flex items-center justify-center gap-x-4 mt-8 p-default md:hidden">
            <img className="w-16 cursor-pointer" src={left_arrow} alt="Previous"></img>
            <img className="w-16 cursor-pointer" src={right_arrow} alt="Next"></img>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
