import React from "react";
import Userform from "./Userform";
import pic1 from "../assets/ellipse-lg.png";
import pic2 from "../assets/ellipse2.png";

const Contacts = () => {
  return (
    <section id="contacts" className="relative bg-black overflow-hidden">
      <img
        className="absolute top-24 -left-16 w-[180vw] h-[60%]"
        src={pic1}
        alt=""
      ></img>
      <img
        className="absolute top-24 -left-16 w-[180vw] h-[60%]"
        src={pic1}
        alt=""
      ></img>
      <img
        className="absolute bottom-8 -right-16"
        src={pic2}
        alt=""
      ></img>
      <img
        className="absolute bottom-8 right-0"
        src={pic2}
        alt=""
      ></img>
      <div className="p-default py-default relative flex flex-col">
        <h2 className="text-center">Ready for the next step?</h2>
        <p className="mt-4 text-center">
          Are you ready to improve your workplace? Fill out the form to request
          a demo now.
        </p>
        <Userform />
      </div>
    </section>
  );
};

export default Contacts;
