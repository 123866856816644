import Navbar from "./components/Navbar";
import Header from "./components/Header";
import About from "./components/About";
import Solutions from "./components/Solutions";
import Benefits from "./components/Benefits";
import Features from "./components/Features";
import Addons from "./components/Addons";
import Ondemand from "./components/Ondemand";
import Integrations from "./components/Integrations";
import Target from "./components/Target";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <About />
      <Solutions />
      <Benefits />
      <Features />
      <Addons />
      <Ondemand />
      <Integrations />
      <Target />
      <Contacts />
      <Footer />
    </div>
  );
}

export default App;
