import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Header = () => {
  return (
    <section
      id="header"
      className="bg-black bg-header-lg bg-cover bg-center bg-no-repeat relative"
    >
      <div className="absolute bg-black bg-opacity-50 top-0 left-0 w-full h-full"></div>
      <div className="p-default flex flex-col py-[15vh] sm:py-[20vh] md:py-[25vh] lg:py-[30vh] relative">
        <h1 className="text-center max-w-[760px] xl:max-w-[80%] mx-auto">
          Borderless office. <br></br>At any place. At any time
        </h1>
        <h5 className="mt-4 md:mt-8 text-center">
          Zionys is a fully customizable hybrid office management platform for
          hybrid teams with built-in time tracker
        </h5>
        <AnchorLink className="btn-white" href="#contacts">
          Book a demo
        </AnchorLink>
      </div>
    </section>
  );
};

export default Header;
