import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { send } from "emailjs-com";

const Userform = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [sendSuccess, setSendSuccess] = useState(false);

  const sendFormData = async (data, error) => {
    send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      data,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    );
    reset();
    setSendSuccess(true);
  };
  return (
    <section id="feedback">
      <form
        onSubmit={handleSubmit(sendFormData)}
        className="mx-auto max-w-sm mt-16 bg-transparent"
      >
        <div className="userform flex flex-col userform-border rounded-[40px] p-default py-12 bg-transparent">
          <h3>Book a Demo</h3>
          <label className="text-sm mt-4">Name</label>
          <input
            type="text"
            placeholder="Rachel Joe"
            {...register("name", { required: true, maxLength: 30 })}
          />
          <label className="text-sm mt-4">Email</label>
          <input
            type="email"
            placeholder="yourmail@gmail.com"
            {...register("email", {
              required: true,
              pattern:
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            })}
            aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.email?.type === "required" && (
            <span className="error-span">Please, enter your email</span>
          )}
          {errors.email?.type === "pattern" && (
            <span className="error-span">Please, enter valid email</span>
          )}

          <label className="text-white text-sm mt-4">Company</label>
          <input
            type="text"
            placeholder="Company Name"
            {...register("company", { maxLength: 30, required: true })}
          />
          <label className="text-white text-sm mt-4">Message</label>
          <input
            aria-multiline
            type="text"
            placeholder="Start typing a question..."
            defaultValue=""
            {...register("message")}
          />
          {sendSuccess && (
            <span className="success-span mt-4">
              Thanks, your data was processed successfully!
            </span>
          )}
          <button type="submit" className="btn-white mt-12 mx-auto">
            Book a demo
          </button>
        </div>
      </form>
    </section>
  );
};

export default Userform;
