import React from "react";
import pic1 from "../assets/target1.svg";
import pic2 from "../assets/target2.svg";
import pic3 from "../assets/target3.svg";

const targetList = [
  {
    image: pic1,
    text: "Enterprises with multiple offices",
  },
  {
    image: pic2,
    text: "Co-workings",
  },
  {
    image: pic3,
    text: "Hybrid teams",
  },
];

const Target = () => {
  return (
    <section id="target" className="relative bg-black">
      <div className="p-default py-default relative">
        <h2 className="text-center">Target audience:</h2>
        <div className="flex flex-col md:grid md:grid-cols-2 gap-4 mt-16 max-w-sm md:max-w-[612px] mx-auto">
          {targetList &&
            targetList.map((target, index) => (
              <article
                className={
                  "gradient-border rounded-[20px] bg-th-dark p-8 flex items-center gap-x-4 " +
                  (index === 0 ? "col-span-2" : "")
                }
                key={index}
              >
                <img className="w-14" src={target.image} alt=""></img>
                <p className="text-white text-2xl font-bold">{target.text}</p>
              </article>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Target;
