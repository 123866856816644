import React, { useState } from "react";
import menu_open from "../assets/menu_open.svg";
import logo from "../assets/logo.svg";
import menu_close from "../assets/menu_close.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion, AnimatePresence } from "framer-motion";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0,
        staggerDirection: 1,
      },
    },
  };
  return (
    <section id="navbar" className="bg-black">
      <div className="p-default py-default flex items-center justify-between h-32">
        <a href="/">
          <img className="" src={logo} alt="Zionys"></img>
        </a>
        <nav className="header-nav md:flex items-center gap-x-4 hidden ">
          <AnchorLink href="#solutions">Solutions</AnchorLink>
          <AnchorLink href="#benefits">Benefits</AnchorLink>
          <AnchorLink href="#features">Features</AnchorLink>
          <AnchorLink href="#integrations">Integrations</AnchorLink>
          <AnchorLink href="#contacts">Contacts</AnchorLink>
        </nav>
        <AnchorLink
          className="gradient-border rounded-full px-8 py-3 text-white text-base hidden md:block"
          href="#contacts"
        >
          Get in touch
        </AnchorLink>
        {toggleMenu ? (
          <img
            className="ml-auto cursor-pointer w-14 md:hidden"
            src={menu_close}
            onClick={() => setToggleMenu(false)}
            alt="Close menu"
          ></img>
        ) : (
          <img
            className="ml-auto cursor-pointer w-14 md:hidden"
            src={menu_open}
            alt="Menu"
            onClick={() => setToggleMenu(true)}
          ></img>
        )}
        <AnimatePresence>
          {toggleMenu && (
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: "100%", maxWidth: "100%" }}
              className="absolute w-full h-[100vh] top-0 right-0 bg-black bg-header-lg bg-contain bg-center bg-no-repeat z-50 flex flex-col"
            >
              <motion.div className="flex items-center h-24 p-4">
                <a href="/">
                  <img className="" src={logo} alt="Zionys"></img>
                </a>
                <img
                  className="ml-auto cursor-pointer w-14 md:hidden"
                  src={menu_close}
                  onClick={() => setToggleMenu(false)}
                  alt="Close menu"
                ></img>
              </motion.div>
              <motion.div
                className="header-nav-burger flex flex-col items-start mt-16 gap-y-12 p-4 bg-black/60"
                initial="closed"
                animate="open"
                variants={sideVariants}
              >
                <AnchorLink
                  href="#solutions"
                  onClick={() => setToggleMenu(false)}
                >
                  Solutions
                </AnchorLink>
                <AnchorLink
                  href="#benefits"
                  onClick={() => setToggleMenu(false)}
                >
                  Benefits
                </AnchorLink>
                <AnchorLink
                  href="#features"
                  onClick={() => setToggleMenu(false)}
                >
                  Features
                </AnchorLink>
                <AnchorLink
                  href="#integrations"
                  onClick={() => setToggleMenu(false)}
                >
                  Integrations
                </AnchorLink>
                <AnchorLink
                  href="#contacts"
                  onClick={() => setToggleMenu(false)}
                >
                  Contacts
                </AnchorLink>
              </motion.div>
              <motion.div
                className="p-4 pt-12 bg-black/60"
                initial="closed"
                animate="open"
                variants={sideVariants}
              >
                <AnchorLink
                  className="gradient-border rounded-full px-8 py-3 text-white text-base mr-auto"
                  href="#contacts"
                  onClick={() => setToggleMenu(false)}
                >
                  Get in touch
                </AnchorLink>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default Navbar;
